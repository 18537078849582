@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.footer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  @media (max-width: $gl-breakpoint-webflow-mobile) {
    justify-content: flex-start;
  }
}

.loadingModal {
  margin: auto;
  max-width: 500px;
  height: fit-content;
  width: 90%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;

  &.progress {
    min-height: 180px;
  }
}
