@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.actionButton.actionButton {
  height: 40px;
  padding: 0 20px;

  &.withIcon {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.actionButtonIcon {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-shrink: 0;
  height: 28px;
  padding: 0 4px;
}

.actionButtonText {
  flex: 1;
  line-height: 1.3;

  &.withIcon {
    text-align: center;
    padding: 1px 12px 0;
  }
}
