@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.loader {
  animation: spin 1s cubic-bezier(0, 0.6, 1, 0.6) infinite;
  border-radius: 50%;
  border: 4px solid $clr-blue;
  border-top-color: transparent !important;
  height: 20px;
  width: 20px;

  &.lg {
    height: 40px;
    width: 40px;
  }

  &.sm {
    border-width: 2px;
    height: 12px;
    width: 12px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.progressLoader {
  .progressContainer {
    fill: transparent;
    height: 220px;
    width: 220px;
    transform: rotate(-90deg) scale(0.5);
  }

  .initialProgress {
    transition-delay: 0.3s;
    stroke: $clr-primary;
    stroke-dasharray: 816.814089933;
    stroke-dashoffset: 816.814089933;
    stroke-linecap: round;
    stroke-width: 14px;

    &.started {
      stroke-dashoffset: 220.20352248325;
    }
  }

  .finalProgress {
    transition: 2s ease;
    stroke: $clr-primary;
    stroke-dasharray: 816.814089933;
    stroke-dashoffset: 816.814089933;
    stroke-linecap: round;
    stroke-width: 14px;

    &.started {
      stroke-dashoffset: 190.814089933;
    }
  }

  .track {
    stroke: rgba(0, 0, 0, 0.03);
    stroke-width: 16px;
    stroke-opacity: 1;
  }
}

.fullScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
