@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.label {
  @include font-size($gl-font-size-2xs);
  color: $clr-black-medium;
  font-weight: $gl-font-weight-regular;
  line-height: 1.5;
  letter-spacing: 0.25px;
  margin-bottom: 4px;
  text-transform: uppercase;
  white-space: pre-wrap;

  &.large {
    @include font-size($gl-font-size-base);
    display: flex;
    color: $clr-black-high;
    margin-bottom: 12px;
    text-transform: none;
  }
}

.labelInline {
  margin-right: 15px;
}

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0;

  &.inline {
    align-items: center;
    flex-direction: row;
  }

  &.imageInline {
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
  }

  // Color
  .colorInputContainer {
    position: relative;
    width: 100%;
    max-width: 175px;
  }

  .colorInputPreview {
    border: 1px solid $clr-black;
    height: 19px;
    left: $gl-input-padding-x;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 19px;
    border-radius: 4px;

    &.dark {
      border-color: $clr-white-high;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .colorPopup {
    border-radius: 8px;
    padding: 8px;
  }

  :global(.rcp) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  :global(.rcp-body) {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    padding: 20px 20px 5px;
  }

  :global(.rcp-fields),
  :global(.rcp-fields-floor) {
    align-items: center;
    display: flex;
    gap: 10px;
    width: 100%;
  }

  :global(.rcp-fields-element) {
    align-items: flex-start;
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
    width: 100%;
  }

  :global(.rcp-fields-element-input) {
    background-color: $gl-input-background-color;
    border-radius: $gl-input-border-radius;
    border: $gl-input-border;
    box-shadow: none;
    color: $gl-input-color;
    height: $gl-input-height;
    padding: $gl-input-padding-y $gl-input-padding-x;
    text-transform: uppercase;
    width: 100%;

    &:focus {
      border: $gl-input-active-border;
      box-shadow: none;
      outline: $gl-input-outline;
    }
  }

  :global(.rcp-fields-element-label) {
    margin-bottom: 0;
  }

  :global(.rcp-hue) {
    background-image: linear-gradient(
      90deg,
      red,
      #ff0,
      #0f0,
      #0ff,
      #00f,
      #f0f,
      red
    );
    border-radius: 10px;
    height: 12px;
    position: relative;
    user-select: none;
    width: 100%;
  }

  :global(.rcp-hue-cursor) {
    border-radius: 50%;
    border: 2px solid $clr-white;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    cursor: pointer;
    height: 20px;
    position: absolute;
    transform: translate(-10px, -4px);
    width: 20px;
  }

  :global(.rcp-alpha) {
    position: relative;

    width: 100%;
    height: 12px;

    border-radius: 10px;

    user-select: none;
  }

  :global(.rcp-alpha-cursor) {
    position: absolute;

    width: 20px;
    height: 20px;

    border: 2px solid #ffffff;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0 0.5px;
    box-sizing: border-box;

    transform: translate(-10px, -4px);
  }

  :global(.rcp-saturation) {
    background-image: linear-gradient(transparent, #000),
      linear-gradient(90deg, #fff, transparent);
    border-radius: 10px 10px 0 0;
    position: relative;
    user-select: none;
    width: 100%;
  }

  :global(.rcp-saturation-cursor) {
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    cursor: pointer;
    height: 20px;
    position: absolute;
    transform: translate(-10px, -10px);
    width: 20px;
  }

  // CSS
  .codeArea {
    @include font-size($gl-font-size-base);
    border: $gl-input-border;
    border-radius: $gl-input-border-radius;

    &.withPlaceholder {
      :global(.ace_content) {
        span {
          color: $gl-input-placeholder-color !important;
        }
      }
    }

    &.editLimit {
      width: 100%;
      height: 300px;
      background-color: $clr-background-light;
      color: $clr-black-medium;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: $gl-font-weight-medium;
      padding: 0 20px;
      text-align: center;
    }

    &.borderless {
      border: none;
      border-radius: 0;
    }
  }

  :global(.ace_focus) {
    border: $gl-input-active-border;
    box-shadow: none;
    outline: $gl-input-outline;
  }

  :global(.ace-tomorrow .ace_gutter),
  :global(.ace_gutter-cell) {
    background-color: $clr-gray-lt;
  }

  // File Input
  .fileInput {
    display: none;
  }

  // Image
  .imageInput {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border: 3px dashed #b3b3b3;

    &.darkBackground {
      background-color: $clr-black-high;
    }

    &:hover {
      border-color: $clr-primary;
    }

    &.round {
      border-radius: 50%;
    }

    &.roundedSquare {
      border-radius: 22.37%;
    }

    &.dragging {
      background-color: $clr-gray-lt;
      outline-color: $clr-primary;
    }

    &.uploaded {
      border: none;
      box-shadow: 0 0 0 2px #b3b3b3;

      &:hover {
        box-shadow: 0 0 0 2px $clr-primary;
      }
    }

    &.disabled {
      border: none;
      box-shadow: 0 0 0 2px #b3b3b3;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .imageInputValue {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  .imageInputLoader {
    align-items: center;
    background-color: white;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .imageInputButton {
    margin: 0 !important;
    min-width: 175px;
  }

  .imageInputButtonContainer {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    // width: 100%;
    align-items: flex-start;
    margin-right: 10px;
  }

  .imageInputPlaceholder {
    @include font-size($gl-font-size-2xs);
    left: 50%;
    line-height: 1.2;
    padding: 0 8px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    flex-wrap: wrap;
  }

  // Radio
  .radioItemContainer {
    border: 1px solid $clr-gray-divider;
    border-radius: 8px;

    &.vertical {
      display: flex;
      flex-direction: column;
    }

    &.horizontal {
      display: flex;
      flex-direction: row;
    }

    &.full {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    @include phone {
      display: flex;
      flex-direction: column;
    }
  }

  .radioButtonIcon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .radioItem {
    @include font-size($gl-font-size-xs);
    background-color: none;
    color: $clr-black-medium;
    font-weight: $gl-font-weight-regular;
    line-height: 1.2;
    padding: 9px 16px 9px 8px;
    justify-content: flex-start;
    gap: 8px;

    &.full {
      flex: 1;
    }

    &.lg {
      min-width: 170px;
    }

    &.active {
      background-color: none;
      color: $clr-white;
      cursor: default;

      &.allowBlank {
        cursor: pointer;
      }
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover .radioButtonIcon::after,
    .radioButtonIcon:hover::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $clr-gray-dark;
      opacity: 0.7;
      border-radius: 50%;
    }
  }

  // Checkbox
  .checkboxContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.vertical {
      flex-direction: column;
    }

    &.horizontal {
      flex-direction: row;
    }

    &.full {
      border: 1px solid $clr-gray-divider;
      border-radius: 8px;
      flex-wrap: wrap;
      max-height: 464px;

      @media (max-width: $gl-breakpoint-webflow-phone-w) {
        flex-direction: column;
        max-height: none;
      }
    }
  }

  // Slider
  .sliderInput {
    max-width: 400px;
    color: $clr-primary;
  }

  .sliderThumb {
    background-color: $clr-white;
    border-radius: 50%;
    border: 2px solid $clr-blue-lt;
    cursor: pointer;
    height: 20px;
    outline: none;
    top: -4px;
    transition: border-color 0.3s, box-shadow 0.6s,
      transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    width: 20px;

    &.active {
      border: 2px solid $clr-blue;
      box-shadow: 0 0 0 5px rgba($clr-blue, 0.12);
    }
  }

  :global(.sliderTrack-0) {
    background-color: $gl-slider-active-background-color;
    border-radius: $gl-slider-border-radius;
    height: 100%;
  }

  // Text
  @mixin text-input-base($readOnly: true) {
    border-radius: $gl-input-border-radius;
    border: $gl-input-border;
    box-shadow: none;
    color: $gl-input-color;
    font-weight: $gl-input-font-weight;
    background: transparent;
    font-size: $gl-font-size-base;

    &.dark {
      background-color: $gl-input-background-color-dark;
      border: $gl-input-border-dark;
      color: $gl-input-color-dark;
    }

    &::placeholder {
      color: $gl-input-placeholder-color;
    }

    &:disabled {
      background-color: $clr-gray;
      color: $clr-gray-dk;
      cursor: not-allowed;
    }

    &.focused,
    &:focus {
      border: $gl-input-active-border;
      outline: $gl-input-outline;
    }

    @if $readOnly {
      &:read-only {
        background-color: $clr-gray-light;
      }
    }
  }

  .textInput {
    @include text-input-base;

    height: $gl-input-height;
    padding: $gl-input-padding-y $gl-input-padding-x;

    &.textOnly {
      padding: 0 !important;
      border-radius: 0;
      height: auto !important;
      border: none !important;
      background-color: transparent;
      color: inherit;
    }
  }

  .textInputColor {
    @include text-input-base(false);

    height: $gl-input-height;
    padding: $gl-input-padding-y $gl-input-padding-x;
    text-indent: 26px;
    width: 100%;
  }

  .numberInputContainer {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }

  .numberInput {
    @include text-input-base;

    border: 1px solid $clr-border;
    height: $gl-input-height;
    padding: $gl-input-padding-y ($gl-input-padding-x + 20px)
      $gl-input-padding-y $gl-input-padding-x;
    width: 100%;
  }

  .numberInputStepContainer {
    border-left: 1px solid $clr-border;
    display: flex;
    flex-direction: column;
    height: calc(100% - 2px);
    overflow: hidden;
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 20px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;

    &.focused,
    &:focus {
      border-color: $gl-input-active-border-color;

      .numberInputStep {
        border-color: $gl-input-active-border-color;
      }
    }
  }

  .numberInputStep {
    @include font-size($gl-font-size-2xs);
    align-items: center;
    background: none;
    border: none;
    box-shadow: none;
    color: #666666;
    display: flex;
    flex: 1;
    justify-content: center;
    line-height: 1;
    padding: 0;

    &:first-of-type {
      border-bottom: 1px solid $clr-border;
    }

    &:hover {
      background-color: #ececec;
    }
  }

  // Text Area
  .textAreaInput {
    @include text-input-base;

    min-height: $gl-input-height;
    padding-left: $gl-input-padding-x;
    padding-right: $gl-input-padding-x;
    padding-top: $gl-input-padding-x;
    resize: vertical;
  }
}

.defaultInputField {
  &::placeholder {
    color: $clr-black !important;
    opacity: 1 !important;
  }
}

.inputContainer {
  position: relative;
  width: 100%;

  input,
  textarea {
    width: 100%;
  }
}

.formInput {
  &.error input {
    border-color: $clr-danger;
    color: $clr-danger;
  }
}

.inputError {
  color: $clr-danger;
  line-height: 1.25;

  &.suggestion {
    color: $clr-black;
  }
}

.loader {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}

.inputGroupContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.inline {
    flex-direction: row;
  }
}

.inputGroupItemList {
  column-gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 15px;
  width: 100%;

  @include phone {
    flex-direction: column;
  }

  &.inline {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }
}

.inputGroupItemContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex: 1;
  // max-width: 331px;

  &.inputGroupItemPreview {
    padding: 12px;
    border-radius: 8px;
    background-color: $clr-background-light;
  }
}

.inputGroupItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 375px;
  // width: 300px;
  &.wide {
    max-width: 325px;
  }
}

.inputGroupItemIcon {
  margin-right: 12px;
}

.inputGroupItemLabelContaner {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 4px;
}

.inputGroupItemLabel {
  font-size: $gl-font-size-2xs;
  font-weight: $gl-font-weight-regular;
  line-height: 1;
  color: $clr-black-medium;
  text-transform: uppercase;
  margin: 0;
}

.subsectionContentLabel {
  margin-bottom: 12px;
  color: $clr-black-high;
  font-size: $gl-font-size-base;
  font-weight: $gl-font-weight-regular;
}

.inlineItems {
  display: flex;
  flex-direction: row;
}

.inputSubGroupContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
  row-gap: 16px;

  &.inline {
    flex-direction: row;
  }

  @media screen and (max-width: 1399px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.inputSubGroupItemContainer {
  flex: 1;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.inputSubGroupChildrenContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.superscript {
  font-size: $gl-font-size-3xs;
  font-weight: $gl-font-weight-medium;
  color: $clr-success-dark;
}

.radioLabel {
  display: flex;
  gap: 2px;
}
