@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.popper {
  user-select: none !important;
  z-index: $gl-z-index-max - 1 !important;
  pointer-events: none !important;
}

.tooltip {
  background-color: #333 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-family: $gl-font-family-base !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
}
