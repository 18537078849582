@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.modalContainer {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 22px 24px 32px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 1000px;
  max-height: 850px;
  overflow: hidden;

  &.requestQuoteModal {
    max-width: 700px;
    max-height: 700px;
    height: auto;

    @include mobile {
      width: 100%;
      height: 100%;
      max-height: none;
      max-width: none;
      padding: 10px 16px 20px;
      border-radius: 0;
    }
  }

  @include mobile {
    width: 100%;
    height: 100%;
    max-height: none;
    padding: 10px 16px 20px;
    border-radius: 0;
  }
}

.contactFormOverlay {
  z-index: 1999999999 !important;
}

.none {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.page {
    width: 100%;
  }

  @include mobile {
    margin: 10px 0;
  }
}

.back {
  @include font-size($gl-font-size-m);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 16px;
  min-width: 232px;

  @media screen and (max-width: 991px) {
    color: $clr-primary;
    font-weight: $gl-font-weight-medium;
    min-width: 0;
  }

  &.modalButton {
    margin: 0 16px;
  }

  &.footerButton {
    margin: 0 16px;
    font-weight: $gl-font-weight-medium;
    min-width: 232px;
  }

  @include mobile {
    margin: 0 6px;
  }

  &.isFullSevice {
    @include phone {
      text-align: left;
      max-width: 117px;
      margin-top: 15px;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.headerButton {
  height: 40px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.headerTitle {
  @include font-size($gl-font-size-5xl);
  color: $clr-primary;
  font-family: $gl-font-family-page-header;
  line-height: 1;
  margin: 0;
}

.formContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 650px;
  width: 100%;
  padding: 0 15px;
  margin: 15px auto 0;

  &.modal {
    @media screen and (min-width: 991px) {
      max-height: 4850px;
      margin: 10px auto 0;
      gap: 8px;
    }
  }

  &.success {
    margin: 40px auto 0;
  }

  @media screen and (min-width: 991px) {
    gap: 8px;
  }
}

.nameContainer {
  display: flex;
  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: $gl-breakpoint-webflow-phone) {
    flex-direction: column;
  }
}

.nameInput {
  flex: 1;
  width: 100%;
}

.inputContainer {
  width: 100%;
}

.labelInput {
  margin-bottom: 6px;
}

.footerContainer {
  font-size: $gl-font-size-subtitle2;
  text-align: center;
}

.footer {
  background: white;
  width: 100%;
  padding: 0 15px;
  margin: 15px 0 0;
  display: flex;
  gap: 8px;
  max-width: 800px;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    padding: 10px 15px 0;
    flex-direction: column;
  }

  &.page {
    max-width: 650px;
    padding: 0 15px;
    margin: 12px auto 0;
  }

  @include phone {
    flex-direction: column;
    row-gap: 16px;
  }
}

.radioInputContainer {
  // display: flex;
  // flex-wrap: wrap;
  margin-top: 3px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 1rem), 1fr));
  width: 100%;

  @media screen and (max-width: $gl-breakpoint-webflow-mobile) {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 0.5rem), 1fr));
  }
}

.radioButton {
  flex: 1;
  text-align: left;
}

.formContainer {
  width: 100%;
  &.modal {
    @media screen and (max-width: 991px) {
      overflow: auto;
    }
  }
}

.modalFooterButton {
  padding: 0 30px;
}

.contactFormContainer {
  background-color: $clr-white-high;
  max-width: 700px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px;
  border-radius: 16px;
  min-height: 563px;

  @media screen and (max-width: 991px) {
    margin: 0 auto;
  }
}
