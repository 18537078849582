@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.toastContainer {
  z-index: $gl-z-index-toast;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: none;
  z-index: 99999;

  &.visible {
    display: block;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

.main {
  flex: 1;

  &.compress {
    overflow: auto;

    @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
      min-height: 200px;
    }
  }

  &.contact {
    background-color: $clr-white-high;

    @media screen and (max-width: 991px) {
      overflow: auto;
    }
  }
}
