@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.overlay {
  background-color: rgba(30, 73, 110, 0.3);
  backdrop-filter: blur(7.5px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $gl-z-index-max - 2 !important;
}

.content {
  background-color: $clr-white;
  border-radius: 16px;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  outline: none;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &.fullscreen {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  position: relative;
  background-color: $clr-light-gray;

  @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
    padding: 14px 16px 14px;
  }
}

.headerTitle {
  @include font-size($gl-font-size-xl);
  align-items: center;
  display: flex;
  font-weight: $gl-font-weight-bold;
  line-height: 36px;
  margin: 0;
}

.headerTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerSubtitle {
  font-size: $gl-font-size-base;
  line-height: 24px;
  max-width: 745px;
  color: $clr-black-medium;
  margin-top: 8px;
}

.headerClose {
  position: absolute;
  right: 15px;
  top: 15px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 30px 30px 20px;
}
