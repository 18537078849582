@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.bannerContainer,
.notificationContainer {
  @include font-size($gl-font-size-base);
  align-items: center;
  display: flex;
  gap: 20px;
  min-height: 72px;
  justify-content: space-between;
  padding: 8px 16px !important;
  border-radius: 0 !important;

  @include mobile {
    min-height: 64px;
    gap: 10px;
    padding: 5px 12px 5px 10px;
  }

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    padding: 14px 10px 14px 8px !important;
  }
}

.bannerContent {
  display: flex;
  gap: 14px;
  align-items: center;

  @include mobile {
    gap: 10px;
  }

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    gap: 8px !important;
    align-items: flex-start;
  }
}

.bannerIcon {
  align-items: center;
  display: flex;
  justify-content: center;

  @include mobile {
    scale: 0.8;
  }
}

.bannerMessage {
  color: $clr-black;

  a {
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }

  @include mobile {
    @include font-size($gl-font-size-s);
    line-height: 1.8;
  }

  @include phone {
    line-height: 1.4;
  }
}

.bannerButtons {
  align-items: center;
  display: flex;
  gap: 26px;

  @include mobile {
    gap: 15px;
  }
}

.button {
  min-width: 150px;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    display: none;
  }
}

.buttonMobile {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    @include font-size($gl-font-size-xs);
    display: block;
    width: 180px;
    padding: 8px 0;
    margin: 0 auto;
  }
}

.notificationContainer,
.bannerContainer {
  background-color: #f6f6f6;
  padding: 10px 15px;
  border-radius: 8px;
}

.notificationContainer {
  &.red {
    background-color: #f8e5e5;
    color: #c62828;
    border-bottom: 1px solid #c62828;
  }

  &.yellow {
    background-color: #fcf9e0;
    color: #ffc107;
    border-bottom: 1px solid #ffc107;
  }

  &.orange {
    background-color: #fff0d9;
    color: #ffa000;
    border-bottom: 1px solid #ffa000;
  }

  &.darkBlue {
    background-color: #e4e9ee;
    color: $clr-primary;
    border-bottom: 1px solid $clr-primary;
  }

  &.green {
    background-color: #ebf8e5;
    color: #00c853;
    border-bottom: 1px solid #00c853;
  }

  &.blue {
    background-color: $clr-info-light;
    color: $clr-info-dark;
    border-bottom: 1px solid $clr-info-dark;
  }

  &.gray {
    background-color: #f6f6f6;
    color: $clr-black-medium;
    border-bottom: 1px solid $clr-black-medium;
  }

  &.announcement {
    background-color: #f7c406;
    color: $clr-black;
    border-bottom: 1px solid $clr-black;
  }

  &.borderless {
    border: none !important;
  }

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    gap: 8px;
    align-items: flex-start;
  }
}

.bannerContainer {
  border-radius: 8px !important;

  &.red {
    background-color: #f8e5e5;
    color: #c62828;
    border: 1px solid #c62828;
  }

  &.yellow {
    background-color: #fcf9e0;
    color: #ffc107;
    border: 1px solid #ffc107;
  }

  &.orange {
    background-color: #fff0d9;
    color: #ffa000;
    border: 1px solid #ffa000;
  }

  &.darkBlue {
    background-color: #e4e9ee;
    color: $clr-primary;
    border: 1px solid $clr-primary;
  }

  &.green {
    background-color: #ebf8e5;
    color: #00c853;
    border: 1px solid #00c853;
  }

  &.blue {
    background-color: #4299ed26;
    color: $clr-info-dark;
    border: 1px solid #4299ed40;
  }

  &.gray {
    background-color: #f6f6f6;
    color: $clr-black-medium;
    border: 1px solid $clr-black-medium;
  }

  &.announcement {
    background-color: #f7c406;
    color: $clr-black;
    border: 1px solid $clr-black;
  }

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    gap: 8px;
    align-items: flex-start;
  }
}

.messageAndButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    gap: 8px;
  }
}

.closeIcon {
  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    margin-top: 2px;
  }
}
