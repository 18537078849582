@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.rcp {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $gl-input-border-radius;
  background-color: $clr-white;
}

.rcpBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  box-sizing: border-box;

  padding: 20px 10px 10px;
}

.rcpSaturation {
  position: relative;

  width: 100%;
  background-image: linear-gradient(transparent, black),
    linear-gradient(to right, white, transparent);
  border-radius: $gl-input-border-radius;

  user-select: none;
}

.rcpSaturationCursor {
  position: absolute;

  width: 20px;
  height: 20px;

  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;

  transform: translate(-10px, -10px);
}

.rcpHue {
  position: relative;

  width: 100%;
  height: 12px;

  background-image: linear-gradient(
    to right,
    rgb(255, 0, 0),
    rgb(255, 255, 0),
    rgb(0, 255, 0),
    rgb(0, 255, 255),
    rgb(0, 0, 255),
    rgb(255, 0, 255),
    rgb(255, 0, 0)
  );
  border-radius: 10px;

  user-select: none;
}

.rcpHueCursor {
  position: absolute;

  width: 20px;
  height: 20px;

  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0.5px;
  box-sizing: border-box;

  transform: translate(-10px, -4px);
}

.rcpAlpha {
  position: relative;

  width: 100%;
  height: 12px;

  border-radius: 10px;

  user-select: none;
}

.rcpAlphaCursor {
  position: absolute;

  width: 20px;
  height: 20px;

  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0.5px;
  box-sizing: border-box;

  transform: translate(-10px, -4px);
}

.rcpFields {
  display: flex;
  gap: 16px;
  width: 100%;
}

.rcpFieldsContainer {
  display: flex;
  flex-direction: column;
}

.rcpFieldsElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.suggested {
    margin-top: 15px;
  }
}

.rcpFieldsElementInput {
  width: 100%;

  @include font-size($gl-font-size-base);
  font-weight: $gl-font-weight-medium;

  color: $clr-black;

  background: none;
  border: $gl-input-border;
  border-radius: $gl-input-border-radius;
  box-sizing: border-box;
  outline: none;
  padding: 10px 15px;
  text-transform: uppercase;
}

.rcpFieldsElementLabel {
  @include font-size($gl-font-size-2xs);
  font-weight: $gl-font-weight-regular;

  color: $clr-black-medium;
  width: 100%;
  margin: 0;
}

.rcpFieldsSuggestions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rcpFieldsSuggestionItem {
  width: 24px;
  height: 24px;
  border: 1px solid $clr-border;
  border-radius: 4px;

  &:disabled {
    border: none;
    cursor: default;
  }
}
