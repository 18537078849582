@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.popConfirm {
  @include font-size($gl-font-size-xs);
  background: $clr-white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  line-height: 1.5;
  max-width: 250px;
  z-index: 3147483646;
}

.popConfirmArrow {
  position: absolute;
  width: 10px;
  height: 10px;

  &:after {
    background-color: white;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    content: " ";
    height: 10px;
    position: absolute;
    transform: rotate(225deg);
    width: 10px;
  }
}

[data-popper-placement="top"] .popConfirmArrow {
  bottom: -5px;

  &:after {
    transform: rotate(225deg);
  }
}

[data-popper-placement="bottom"] .popConfirmArrow {
  top: -5px;

  &:after {
    transform: rotate(45deg);
  }
}

[data-popper-placement="left"] .popConfirmArrow {
  right: -5px;

  &:after {
    transform: rotate(135deg);
  }
}

[data-popper-placement="right"] .popConfirmArrow {
  left: -5px;

  &:after {
    transform: rotate(315deg);
  }
}

.popConfirmContent {
  padding: 12px 16px;
}

.popConfirmButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.popConfirmButtonsCancel {
  margin-right: 10px;
}

.confirm {
  background-color: #ebf8e5;
  color: $clr-primary-500;
}
