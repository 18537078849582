@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.container {
  align-items: center;
  display: flex;
  gap: 4px;
}

.fileInputNameContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.filename {
  @include font-size($gl-font-size-s);
  color: $clr-black;
  font-style: italic;
  font-weight: $gl-font-weight-medium;
  margin: 0 5px 0 10px;
  text-decoration: underline !important;
}

.button {
  color: $clr-black;
  height: 30px;
  transition: all 200ms ease;
  width: 30px;

  &:hover {
    color: $clr-yellow !important;
  }
}
