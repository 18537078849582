@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.button {
  align-items: center;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none;
  padding: 0;
  text-decoration: none;
  user-select: none;
  position: relative;

  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.icon {
    &:hover {
      color: $clr-green;
    }
  }
}

.basic,
.text,
.danger,
.light,
.outlined,
.primary,
.secondary {
  border-radius: 12px;
  line-height: 1rem;
  padding: 13px 16px;

  @at-root a#{&} {
    @include font-size($gl-font-size-xs);
    font-weight: $gl-font-weight-regular;
    letter-spacing: 1.25px;
    text-decoration: none;
  }
}

.text {
  color: $clr-black;
  transition: all 200ms ease;

  &:hover {
    background-color: $clr-primary-50;

    &.disabled,
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
    }
  }
}

.basic {
  background-color: #e9e9e9;
  color: $clr-black;

  &:hover {
    background-color: darken(#e9e9e9, 5%);
    color: $clr-black;
  }

  a {
    color: $clr-black;
  }
}

.light {
  background-color: $clr-primary-50;
  color: $clr-primary-500;

  &:hover {
    background-color: darken($clr-primary-50, 5%);
    color: $clr-primary-500;

    &.disabled,
    &:disabled {
      background-color: $clr-primary-50;
    }
  }

  &:focus {
    color: $clr-primary-500;
  }

  a {
    color: $clr-black;
  }
}

.outlined {
  background-color: $clr-white;
  border: 1px solid $clr-black;
  color: $clr-black;

  &:hover {
    background-color: $clr-primary-50;
    color: $clr-black;

    &.disabled,
    &:disabled {
      background-color: transparent;
    }
  }

  a {
    color: $clr-black;
  }
}

.link {
  color: $clr-primary;
  font-weight: $gl-font-weight-bold;
  text-decoration: underline;
  transition: all 200ms ease;

  &:hover {
    color: $clr-green;
    text-decoration: underline;
  }

  &:focus {
    color: $clr-primary;
  }
}

.primary {
  background-color: $clr-primary;
  color: $clr-white;

  &:disabled,
  &.disabled {
    opacity: 0.5;
    color: $clr-white-disabled;
  }

  &:hover {
    background-color: $clr-primary-400;
    color: $clr-white;

    &.disabled,
    &:disabled {
      background-color: $clr-primary;
      opacity: 0.5;
      color: $clr-white-disabled;
    }
  }

  &:focus {
    color: $clr-white;
  }

  a {
    color: $clr-white;
  }
}

.secondary {
  background-color: $clr-secondary;
  color: $clr-primary;
  font-weight: $gl-font-weight-semibold;

  &:disabled,
  &.disabled {
    opacity: 0.6;
  }

  &:hover {
    background-color: $clr-secondary-hover;
    color: $clr-primary;

    &.disabled,
    &:disabled {
      background-color: $clr-secondary;
    }
  }

  &:focus {
    color: $clr-primary;
  }

  a {
    color: $clr-primary;
  }
}

.danger {
  background-color: #ff00001a;
  color: $clr-error-dark;
  padding: 13px 30px;

  &:disabled,
  &.disabled {
    opacity: 0.6;
  }

  &:hover {
    background-color: $clr-danger-hover;
    color: $clr-white;

    &.disabled,
    &:disabled {
      background-color: $clr-danger;
    }
  }

  &:focus {
    color: $clr-error-dark;
  }

  &:active {
    color: $clr-error-dark;
  }

  a {
    color: $clr-white;
  }
}

.lg {
  min-height: $gl-button-height-lg;
  padding: 10px 16px;
}

.sm {
  @include font-size($gl-font-size-2xs);
  min-height: 24px;
  padding: 0 8px;
}

.loader {
  // margin-left: 5px;
  position: absolute;
  right: 13px;
}

.wide {
  padding-left: 0;
  padding-right: 0;
  width: $gl-button-width-wide;
}

.urlIcon {
  margin-left: 4px;
  vertical-align: middle;
}
